import React from "react";
import "./DirectionButton.css";

interface DirectionButtonProps {
  lat: number;
  lng: number;
}

const DirectionButton: React.FC<DirectionButtonProps> = ({ lat, lng }) => {
  const openGoogleMaps = () => {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
    window.open(url, "_blank");
  };

  return (
    <button className="directions-button" onClick={openGoogleMaps}>
      Get Directions
    </button>
  );
};

export default DirectionButton;