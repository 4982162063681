import React from 'react'
import { HStack, VStack, Box, Text, Image, useMediaQuery } from '@chakra-ui/react'
import brick from '../Images/brick.png'
import quotation from '../Images/quotation.png'

const Testimonials = () => {
    const [isMobile] = useMediaQuery("(max-width: 30em)")
    const [isLandscape] = useMediaQuery("(max-height: 30em) and (orientation: landscape)");
    return ( isLandscape ? (
        <HStack width='100%' height='40vh' spacing={0}>
            <Box
                backgroundImage={`url(${brick})`}
                backgroundPosition="center"
                backgroundRepeat="no-repeat"
                backgroundSize="cover"
                width='33.3%'
                height='100%'
                display='flex'
                alignItems='center'
                justifyContent='center'
                position='relative'
            >
                <VStack 
                justifyContent='center'
                alignItems='center'
                textAlign='center'
                width={isMobile ? ' 90%' : '85%'}
                height='100%'
                position='relative'
                >
                    
                    <Text color='black' fontFamily='Georgia, sans-serif' fontWeight='bold' fontSize='sm'>
                    "The best authentic Korean food in the area! I love 
                    coming here to buy Korean groceries and homemade food 
                    at great prices, the owner and rest of the staff are 
                    absolutely the best!"
                    </Text>
                </VStack>
            </Box>
            <Box
                backgroundImage={`url(${brick})`}
                backgroundPosition="center"
                backgroundRepeat="no-repeat"
                backgroundSize="cover"
                width='33.3%'
                height='100%'
                display='flex'
                alignItems='center'
                justifyContent='center'
            >
                <VStack 
                justifyContent='center'
                alignItems='center'
                textAlign='center'
                width='90%'
                height='100%'
                position='relative'
                
                >
                   
                    <Text color='black' fontFamily='Georgia, sans-serif' fontWeight='bold' fontSize='sm'>
                    "Small gem in Callaway. Super friendly staff & even better food! Ordered the 
                    Japchae, Kimbap, bulgogi, dumplings & chicken - seriously didn't 
                    disappoint! Check it out if you're in the area!"
                    </Text>
                </VStack>
            </Box>
            <Box
                backgroundImage={`url(${brick})`}
                backgroundPosition="center"
                backgroundRepeat="no-repeat"
                backgroundSize="cover"
                width='33.3%'
                height='100%'
                display='flex'
                alignItems='center'
                justifyContent='center'
            >
                <VStack 
                justifyContent='center'
                alignItems='center'
                textAlign='center'
                width='70%'
                height='100%'
                position='relative'>
                    
                    <Text color='black' fontFamily='Georgia, sans-serif' fontWeight='bold' fontSize='sm'>
                    "Wonderful owners that 
                    are so helpful just in case you need their expert advice. A hidden delicacy and 
                    treat right in Callaway. I highly recommend this place!"
                    </Text>
                </VStack>
            </Box>
        </HStack>
    ) : (
        <HStack width='100%' height='40vh' spacing={0}>
            <Box
                backgroundImage={`url(${brick})`}
                backgroundPosition="center"
                backgroundRepeat="no-repeat"
                backgroundSize="cover"
                width='33.3%'
                height='100%'
                display='flex'
                alignItems='center'
                justifyContent='center'
                position='relative'
            >
                <VStack 
                justifyContent='center'
                alignItems='center'
                textAlign='center'
                width={isMobile ? ' 90%' : '85%'}
                height='100%'
                position='relative'
                top='-30px'
                marginTop='10'
                >
                    {!isMobile && <Image src={quotation} width='37%' height='45%' marginBottom={0}/>}
                    <Text color='black' fontFamily='Georgia, sans-serif' fontWeight='bold' fontSize={['xs', 'sm', 'md', 'lg']}>
                    "The best authentic Korean food in the area! I love 
                    coming here to buy Korean groceries and homemade food 
                    at great prices, the owner and rest of the staff are 
                    absolutely the best!"
                    </Text>
                </VStack>
            </Box>
            <Box
                backgroundImage={`url(${brick})`}
                backgroundPosition="center"
                backgroundRepeat="no-repeat"
                backgroundSize="cover"
                width='33.3%'
                height='100%'
                display='flex'
                alignItems='center'
                justifyContent='center'
            >
                <VStack 
                justifyContent='center'
                alignItems='center'
                textAlign='center'
                width='85%'
                height='100%'
                position='relative'
                top='-30px'
                marginTop='10'>
                    {!isMobile && <Image src={quotation} width='37%' height='45%' marginBottom={0}/>}
                    <Text color='black' fontFamily='Georgia, sans-serif' fontWeight='bold' fontSize={['xs', 'sm', 'md', 'lg']}>
                    "Small gem in Callaway. Super friendly staff & even better food! Ordered the 
                    Japchae, Kimbap, bulgogi, dumplings & chicken - seriously didn't 
                    disappoint! Check it out if you're in the area!"
                    </Text>
                </VStack>
            </Box>
            <Box
                backgroundImage={`url(${brick})`}
                backgroundPosition="center"
                backgroundRepeat="no-repeat"
                backgroundSize="cover"
                width='33.3%'
                height='100%'
                display='flex'
                alignItems='center'
                justifyContent='center'
                position='relative'
            >
                <VStack 
                justifyContent='center'
                alignItems='center'
                textAlign='center'
                width='85%'
                height='100%'
                position='relative'
                top='-30px'
                marginTop='10'>
                    {!isMobile && <Image src={quotation} width='37%' height='45%' marginBottom={0}/>}
                    <Text color='black' fontFamily='Georgia, sans-serif' fontWeight='bold' fontSize={['xs', 'sm', 'md', 'lg']}>
                    "Wonderful owners that 
                    are so helpful just in case you need their expert advice. A hidden delicacy and 
                    treat right in Callaway. I highly recommend this place!"
                    </Text>
                </VStack>
            </Box>
        </HStack>
    )
    )
}

export default Testimonials