import React from 'react'
import { Stack, HStack, Image, Text, Box, useMediaQuery } from '@chakra-ui/react'
import img8 from '../Images/img8.jpg'


const About = () => {
    const [isMobile] = useMediaQuery("(max-width: 30em)");
    const [isLandscape] = useMediaQuery("(max-height: 30em) and (orientation: landscape)");
    return (
        isMobile ? ( 
            <Stack
            backgroundColor='black'
            alignItems='center'
            justifyContent='center'
            width='100%'
            height='30vh'>
                <Box 
                width='90%' 
                height='100%' 
                textAlign='center' 
                display='flex' 
                flexDirection='column' 
                justifyContent='center' 
                alignItems='center'>
                <Text color='red' paddingBottom='2' fontFamily='Georgia, sans-serif' fontSize= {['md', 'lg', 'xl', '2xl']} fontWeight='extrabold'>
                    Get To Know Us
                </Text>
                <Text color='white' fontSize={['sm', 'md', 'lg', 'xl']} fontFamily='Georgia, sans-serif' paddingBottom='4'>
                    We are a family-owned business dedicated to serving
                    authentic, delicious Korean cuisine. Whether you're 
                    looking to sit down and enjoy lunch or order takeout,
                    we have something for everyone at The Korean Store.
                </Text>
                <Text color='white' fontSize={['sm', 'md', 'lg', 'xl']} fontFamily='Georgia, sans-serif'>
                    In addition to our restaurant offering, we also provide 
                    Korean groceries where you can find snacks and ingredients
                    to make your favorite Korean dishes at home.
                </Text>
                </Box>
                
            </Stack>
        ) : (isLandscape ? (
            <Stack
            backgroundColor='black'
            alignItems='center'
            justifyContent='center'
            width='100%'
            height='60vh'>
                <Box 
                width='90%' 
                height='100%' 
                textAlign='center' 
                display='flex' 
                flexDirection='column' 
                justifyContent='center' 
                alignItems='center'>
                <Text color='red' paddingBottom='2' fontFamily='Georgia, sans-serif' fontSize= {['md', 'lg', 'xl', '2xl']} fontWeight='extrabold'>
                    Get To Know Us
                </Text>
                <Text color='white' fontSize={['sm', 'md', 'lg', 'xl']} fontFamily='Georgia, sans-serif' paddingBottom='4'>
                    We are a family-owned business dedicated to serving
                    authentic, delicious Korean cuisine. Whether you're 
                    looking to sit down and enjoy lunch or order takeout,
                    we have something for everyone at The Korean Store.
                </Text>
                <Text color='white' fontSize={['sm', 'md', 'lg', 'xl']} fontFamily='Georgia, sans-serif'>
                    In addition to our restaurant offering, we also provide 
                    Korean groceries where you can find snacks and ingredients
                    to make your favorite Korean dishes at home.
                </Text>
                </Box>
                
            </Stack>
        ) : (
        <HStack width='100%' height='40vh' spacing={0}>
            <Stack 
            backgroundColor='black'
            alignItems='center'
            justifyContent='center'
            width='50%'
            height='100%'>
                <Image src={img8} width='50%' height='70%'>
                    </Image>   
            </Stack>
            <Stack
            backgroundColor='black'
            alignItems='center'
            justifyContent='center'
            width='50%'
            height='100%'>
                <Box 
                width='100%' 
                height='100%' 
                textAlign='center' 
                display='flex' 
                flexDirection='column' 
                justifyContent='center' 
                alignItems='center'>
                <Text color='red' paddingBottom='2' fontFamily='monospace' fontSize= {['md', 'lg', 'xl', '2xl']} fontWeight='extrabold'>
                    Get To Know Us
                </Text>
                <Text color='white' fontSize={['sm', 'md', 'lg', 'xl']} fontFamily='Georgia, sans-serif' paddingBottom='4'>
                    We are a family-owned business dedicated to serving
                    authentic, delicious Korean cuisine. Whether you're 
                    looking to sit down and enjoy lunch or order takeout,
                    we have something for everyone at The Korean Store.
                </Text>
                <Text color='white' fontSize={['sm', 'md', 'lg', 'xl']} fontFamily='Georgia, sans-serif'>
                    In addition to our restaurant offering, we also provide 
                    Korean groceries where you can find snacks and ingredients
                    to make your favorite Korean dishes at home.
                </Text>
                </Box>
                
            </Stack>
        </HStack>
        )
        )
    )
}

export default About