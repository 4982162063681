import React from 'react'
import { useMediaQuery, Stack, Box, Text, Button, Modal, ModalOverlay, ModalContent, ModalBody, Image, useDisclosure } from '@chakra-ui/react'
import Menu from '../Images/Menu.png'
const Food = () => {
    const [isMobile] = useMediaQuery("(max-width: 30em)");
    const [isLandscape] = useMediaQuery("(max-height: 30em) and (orientation: landscape)");
    const {isOpen, onOpen, onClose } = useDisclosure();
    return (
        <Stack 
        height={isLandscape ? '60vh' : (isMobile ? '30vh' : '40vh')} 
        bgColor='black'
        justifyContent= 'center'
        textAlign='center'
        alignItems='center'
        >
            <Box width = {isLandscape ? '90%' : (isMobile ? '80%' : '40%')}>
                <Text fontSize={['lg', 'xl', '2xl', '3xl']} color='red.600' paddingBottom='5' fontFamily='Georgia, sans-serif' fontWeight='extrabold'>
                    DINE IN OR TAKE OUT 
                </Text>
                <Text fontSize={['sm', 'md', 'lg', 'xl']} color='white' paddingBottom='15' fontFamily='Georgia, sans-serif'>
                    At The Korean Store, we have a variety of authentic dishes on the 
                    menu. Whether you are seasoned in Korean cuisine, trying something 
                    new, or a beloved regular, we have the perfect plate for you.
                </Text>
                <Button colorScheme='red' width={isLandscape ? '20%' : '30%'} height='20%' fontSize={['sm', 'md', 'lg', 'xl']} fontFamily='Georgia, sans-serif' onClick={onOpen}>View Menu</Button>

                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                        <ModalContent maxW={isMobile ? '95vw' : '70vw'} >
                            <ModalBody>
                                <Image src={Menu} alt="Front Menu" width='1582px' height={isMobile ? '80vh' : '80vh'}/>
                            </ModalBody>
                        </ModalContent>
                </Modal>
            </Box>     
        </Stack >
    )
}

export default Food