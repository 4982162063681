import { Stack, Image, Spacer, useMediaQuery} from '@chakra-ui/react'
import logo from './Logo.png'
import logos from './Logos.png'
import logos2 from './Logos2.png'

const Logo: React.FC = () => {
    const [isMobile] = useMediaQuery("(max-width: 30em)");
    const [isLandscape] = useMediaQuery("(max-height: 30em) and (orientation: landscape)");
    return (
        <Stack
        direction='column'
        justifyContent='center'
        alignItems='center'
        width='100%'
        height={isMobile ? '45vh' : '90vh'}
        paddingTop={isMobile || isLandscape ? '0' : '16'}
        >
            <Spacer />
            <Image 
            width={isMobile ? '60vw' : '37vw'}
            height={isMobile ? '45%' : '35vh'}
            src={logo}
            >
            </Image> 
            <Spacer />   
            <Image
                width='100%'
                height={isMobile ? '20vh' : '40vh'}
                src={isMobile ? logos2 : logos}
            ></Image>        
        </Stack>
        
    )
}

export default Logo