import React from 'react'
import { Stack, Text, HStack, Box, Link, Image, useMediaQuery, VStack, Flex } from '@chakra-ui/react'
import Map from './Map'
import facebookLogo from '../Images/fblogo.png'

const Contact = () => {
    const [isMobile] = useMediaQuery("(max-width: 30em)")
    return ( 
        isMobile ? (
            <VStack 
                width='100%' 
                height='50vh' 
                spacing={0}>
                <Flex
                    width='100%' 
                    height='100%'
                    backgroundColor='red.600'
                    alignItems='center'
                    justifyContent='center'
                    direction="column">
                    <Text color='white' fontFamily='Georgia, sans-serif' fontWeight='extrabold' fontSize={['lg', 'xl', '2xl', '3xl']} paddingBottom={15}>
                        Contact Us
                    </Text>
                    <Text color='white' fontFamily='Georgia, sans-serif' fontWeight='bold' fontSize={['sm', 'md', 'lg', 'xl']}>
                        (850) 874-1651
                    </Text>
                    <Text color='white' fontFamily='Georgia, sans-serif' fontWeight='bold' fontSize={['sm', 'md', 'lg', 'xl']}>
                        6121 Cherry Street
                    </Text>
                    <Text color='white' fontFamily='Georgia, sans-serif' fontWeight='bold' fontSize={['sm', 'md', 'lg', 'xl']} paddingBottom='2px'>
                        Panama City, FL 32405
                    </Text>
                    <Link href="https://www.facebook.com/koreanstorecherrystreet/?paipv=0&eav=Afak6sFpZMUMigseWDEEcHvXtx4LzqZIio149ze8ggqURozBKJy3f4HaGveyr7Kptt4&_rdr" isExternal>
                        <Image src={facebookLogo} alt="Facebook" className="facebook-logo" 
                        width='40px' height='40px' marginTop='10px' />
                    </Link>
                </Flex>
                <Stack 
                width='100%' 
                height='100%'
                backgroundColor='red.600'
                alignItems='center'
                justifyContent='center'>
                    <Map />
                </Stack>
            </VStack>
        )  : (
    <HStack 
    width='100%' 
    height='50vh' 
    spacing={0}>
        <Stack 
        width='50%' 
        height='100%'
        backgroundColor='red.600'
        alignItems='center'
        justifyContent='center'>
            <Box textAlign='left'
            position='relative'
            left='14'>
                <Text color='white' fontFamily='Georgia, sans-serif' fontWeight='extrabold' fontSize={['lg', 'xl', '2xl', '3xl']} paddingBottom={15}>
                    Contact Us
                </Text>
                <Text color='white' fontFamily='Georgia, sans-serif' fontWeight='bold' fontSize={['sm', 'md', 'lg', 'xl']}>
                    (850) 874-1651
                </Text>
                <Text color='white' fontFamily='Georgia, sans-serif' fontWeight='bold' fontSize={['sm', 'md', 'lg', 'xl']}>
                    6121 Cherry Street
                </Text>
                <Text color='white' fontFamily='Georgia, sans-serif' fontWeight='bold' fontSize={['sm', 'md', 'lg', 'xl']} paddingBottom='2px'>
                    Panama City, FL 32405
                </Text>
                <Link href="https://www.facebook.com/koreanstorecherrystreet/?paipv=0&eav=Afak6sFpZMUMigseWDEEcHvXtx4LzqZIio149ze8ggqURozBKJy3f4HaGveyr7Kptt4&_rdr" isExternal>
                    <Image src={facebookLogo} alt="Facebook" className="facebook-logo" 
                    width='40px' height='40px' />
                </Link>
            </Box>
        </Stack>
        <Stack 
        width='50%' 
        height='100%'
        backgroundColor='red.600'
        alignItems='center'
        justifyContent='center'>
            <Map />
        </Stack>
    </HStack>
    )
    )
    
}

export default Contact