import React, {useRef} from "react";
import './Map.css'
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import DirectionsButton from "./DirectionButton"

const defaultCenter = {
  lat: 30.1457712,
  lng: -85.5832275,
};

const Map: React.FC = () => {
    return (
      <LoadScript googleMapsApiKey="AIzaSyCgDBG8XNpx594iVX6HPbypOIHVmUQ7ICo">
        <GoogleMap
          mapContainerClassName="map-container"
          center={defaultCenter}
          zoom={15}
        >
          <DirectionsButton lat={defaultCenter.lat} lng={defaultCenter.lng} />
        </GoogleMap>
      </LoadScript>
    );
  };
  
  export default Map;