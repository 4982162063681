import * as React from "react"
import {
  ChakraProvider,
  theme,
  useMediaQuery
} from "@chakra-ui/react"
import Logo from './Components/Logo'
import NavBar from './Components/Navbar'
import Food from './Components/Food'
import Images from './Components/Images'
import About from './Components/About'
import Testimonials from './Components/Testimonials'
import Contact from './Components/Contact'

export const App = () => {
  const [isMobile] = useMediaQuery("(max-width: 30em)");
  const [isLandscape] = useMediaQuery("(max-height: 30em) and (orientation: landscape)");
  return (
    <ChakraProvider theme={theme}>
      {!isMobile && !isLandscape && <NavBar />}
      <div className='home'>
        <Logo />
      </div>
      <div className='menu'>
        <Food />
      </div>
      <div>
        <Images />
      </div>
      <div className='about'>
        <About />
      </div>
      <div>
        <Testimonials />
      </div>
      <div className='contact'>
        <Contact />
      </div>
    </ChakraProvider>
  )
}