import { Box, Flex, Text, Link as ChakraLink } from '@chakra-ui/react';
import { Link as ScrollLink} from 'react-scroll';

const NavBar: React.FC = () => {
  return (
    <Flex 
        as='nav'
        align='center'
        justify='space-between'
        wrap='wrap'
        padding='1.5rem'
        bg='transparent'
        color='white'
        width='100%'
        position='fixed'
        zIndex='10'
    >
        <Text color='red' fontFamily='Syne' fontSize='2xl' marginLeft='5%' letterSpacing='widest' fontWeight='extrabold'>
            The Korean Store
        </Text>
        <Box
            display='flex'
            width='auto'
            alignItems='center'
            marginRight='5%'
        >
            <ChakraLink as={ScrollLink} to="home" smooth duration={500} mr={10} fontFamily='Georgia, sans-serif' fontSize='xl' color='red.500'>
                Home
            </ChakraLink>
            <ChakraLink as={ScrollLink} to="menu" smooth duration={500} mr={10} fontFamily='Georgia, sans-serif' fontSize='xl' color='red.500'>
                Menu
            </ChakraLink>
            <ChakraLink as={ScrollLink} to="about" smooth duration={500} mr={10} fontFamily='Georgia, sans-serif' fontSize='xl' color='red.500'>
                About us
            </ChakraLink>
            <ChakraLink as={ScrollLink} to="contact" smooth duration={500} fontFamily='Georgia, sans-serif' fontSize='xl' color='red.500'>
                Contact Us
            </ChakraLink>
        </Box>
    </Flex>

  );
};

export default NavBar;