import React from 'react'
import { HStack, VStack, Image, AspectRatio, useMediaQuery, Box } from '@chakra-ui/react'
import img1 from '../Images/img1.jpg'
import img2 from '../Images/img2.jpg'
import img3 from '../Images/img3.jpg'
import img4 from '../Images/img4.jpg'
import img5 from '../Images/img5.jpg'
import img6 from '../Images/img6.jpg'
import img7 from '../Images/img7.jpg'


const Images = () => {
    const [isMobile] = useMediaQuery("(max-width: 30em)");
    return (
        isMobile ? (
            <VStack spacing={0} width="100%" >
                <HStack spacing={0} width="100%" height="20vh">
                    <Box width="33.3%" height="100%">
                        <Image src={img1} width="100%" height="100%" objectFit="cover" />
                    </Box>
                    <Box width="33.3%" height="100%">
                        <Image src={img2} width="100%" height="100%" objectFit="cover" />
                    </Box>
                    <Box width="33.3%" height="100%">
                        <Image src={img3} width="100%" height="100%" objectFit="cover" />
                    </Box>
                </HStack>
                <HStack spacing={0} width="100%" height="20vh">
                    <Box width="33.3%" height="100%">
                        <Image src={img4} width="100%" height="100%" objectFit="cover" />
                    </Box>
                    <Box width="33.3%" height="100%">
                        <Image src={img5} width="100%" height="100%" objectFit="cover" />
                    </Box>
                    <Box width="33.3%" height="100%">
                        <Image src={img6} width="100%" height="100%" objectFit="cover" />
                    </Box>
                </HStack>
            </VStack>
        ) : (
            <HStack spacing={0}>
                <AspectRatio ratio={1} flex="1">
                    <Image src={img1} objectFit="cover" />
                </AspectRatio>
                <AspectRatio ratio={1} flex="1">
                    <Image src={img2} objectFit="cover" />
                </AspectRatio>
                <AspectRatio ratio={1} flex="1">
                    <Image src={img3} objectFit="cover" />
                </AspectRatio>
                <AspectRatio ratio={1} flex="1">
                    <Image src={img4} objectFit="cover" />
                </AspectRatio>
                <AspectRatio ratio={1} flex="1">
                    <Image src={img5} objectFit="cover" />
                </AspectRatio>
                <AspectRatio ratio={1} flex="1">
                    <Image src={img6} objectFit="cover" />
                </AspectRatio>
            </HStack>
        )
    )
}

export default Images;